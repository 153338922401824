import React from "react"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import Link from "../common/Link"
export default function SingleKurskategori({ kurser, kursKategori }) {
  const { name, description } = kursKategori
  console.log(kurser)
  return (
    <div className="min-h-screen  flex flex-col items-center justify-start">
      <div className="relative bg-black">
        <StaticImage
          src="../../images/kurser/classroom3.jpg"
          className="h-screen/3 md:h-screen/2 opacity-50"
        />
        <div className="absolute top-0 w-full h-full flex flex-col justify-center ">
          <div className="px-4 md:px-24 max-w-screen-2xl mx-auto w-full">
            <h1 className="inline-block text-white ">{name}</h1>
          </div>
        </div>
      </div>
      <div className="relative px-4 md:px-24 max-w-screen-2xl w-full space-y-8 md:space-y-16 py-8 md:py-16">
        {description && (
          <div className="bg-secondary/10 p-4 md:p-8">
            <p>{description}</p>
          </div>
        )}
        <div className="grid md:grid-cols-3 gap-4 md:gap-8 ">
          {kurser.map(kurs => {
            return <SingleKursListItem key={kurs.id} kurs={kurs} />
          })}
        </div>
      </div>
    </div>
  )
}
function SingleKursListItem({ kurs }) {
  const { title, slug, kursfakta, featuredImage, uri } = kurs
  const { kortText } = kursfakta

  const image = featuredImage?.node.localFile.childImageSharp

  return (
    <div className="flex flex-col md:gap-4 border-b-2 last:border-0 border-secondary/50 md:border-0 pb-4">
      <div className="hidden md:block">
        {" "}
        <Link to={uri}>
          {" "}
          <GatsbyImage image={getImage(image)} alt={title} className="" />
        </Link>
      </div>
      <div className=" flex flex-col space-y-2">
        <div className="font-bold text-2xl tracking-tight ">
          <Link to={uri}>{title}</Link>
        </div>
        <div className="">{kortText}</div>
      </div>
      <div className="font-bold  tracking-tight ">
        <Link to={uri}>
          <span className="underline underline-offset-2 decoration-primary decoration-2  hover:text-primary  transition-all">
            Läs mer
          </span>
        </Link>
      </div>
    </div>
  )
}
